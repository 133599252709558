<template>
  <section>
    <h3>{{ $t("headers.page-not-found") }}</h3>
    <p>
      {{ $t("view.page-not-found.text") }}
    </p>
  </section>
</template>

<script>
export default {
  name: 'ViewNotFound',
};
</script>

<style scoped>
p {
  text-align: center;
}
</style>
